import React, { Fragment } from "react";
import { Tab } from "@headlessui/react";
import PriceContentWrapper from "./PriceContentWrapper";

const PriceContentTabs = ({ contents, days }) => {
  if (!contents)
    return <></>

  if (contents.length === 1)
    return <PriceContentWrapper content={contents[0]} days={days} />
  
  return (
    <Tab.Group defaultIndex={0}> 
    {contents && contents.length > 1 && (
      <Tab.List className="flex mt-8 border-b-2 border-gray-200">
      {contents && contents.map((content, index) => (
          <Tab
            as={Fragment}
            key={index}
          >
            {({selected}) => (
              <button 
                className={`flex items-center justify-center px-8 py-4 text-center border-b-2 mb-[-2px] cursor-pointer ${selected ? 'border-red-800' : 'border-transparent'}`}
              >
              <span className="font-medium font-display">{content.title}</span>
              </button>
            )}
          </Tab>
      ))}
      </Tab.List>
    )}
      <Tab.Panels>
        { contents && contents.map((content, index) => (
          <Tab.Panel key={index}>
            <PriceContentWrapper content={content} days={days} />
            </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default PriceContentTabs;
