import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <div className="w-full h-auto py-4 text-white bg-gray-800">
      <div className="container px-4 mx-auto flex justify-between items-center">
        <span>
        <span className="mr-1">&copy; {new Date().getFullYear()}</span>Görans
          Sportshop
        </span>
          <a href="https://www.instagram.com/gorans_skidshop/" target="_blank">

          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
