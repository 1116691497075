import { faSnowflake } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { scrollToHash } from "../utils/scrollToHash";

const HeroMenu = ({ weatherData }) => {
  const { weather, snow } = weatherData;

  return (
    <div className="items-center justify-center hidden w-full h-auto font-light lg:text-md xl:text-xl lg:flex">
      <a
        href="#about"
        className="mr-4 hover:text-red-800"
        onClick={scrollToHash}
      >
        Om oss
      </a>
      <a
        href="#prices"
        className="mr-4 hover:text-red-800"
        onClick={scrollToHash}
      >
        Priser
      </a>
      <span className="mr-4">
        {weather.temperature.toFixed()}&nbsp;&deg;C
        <i className={`wi wi-owm-${weather.icon} ml-2 text-red-800`}></i>
      </span>
      <StaticImage
        src="../images/logo.png"
        className="flex"
        alt="Görans Logo"
      />
      <span className="ml-4">
        <FontAwesomeIcon className="mr-2 text-red-800" icon={faSnowflake} />
        {snow} cm
      </span>
      <a
        href="https://www.instagram.com/gorans_skidshop/"
        target="_blank"
        className="ml-4 hover:text-red-800"
      >
        Instagram
      </a>
      <a
        href="#contact"
        className="ml-4 hover:text-red-800"
        onClick={scrollToHash}
      >
        Kontakt
      </a>
    </div>
  );
};

export default HeroMenu;
