import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Brands = () => {
  return (
    <div className="h-auto px-8 py-16 bg-white">
      <div className="container content-center mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          <StaticImage
            
            objectFit="contain"
            className="flex items-center w-full saturate-0 opacity-70"
            src="../images/volkl.png"
            alt="Atomic Logo"
          />

          <StaticImage
            objectFit="contain"
            className="flex items-center w-full saturate-0 opacity-70"
            src="../images/madshus.png"
            alt="Atomic Logo"
          />
          <StaticImage
            objectFit="contain"
            className="w-full saturate-0 opacity-80"
            src="../images/atomic.png"
            alt="Fischer Logo"
          />
          <StaticImage
            objectFit="contain"
            className="flex items-center w-full saturate-0 opacity-70"
            src="../images/salomon.png"
            alt="Head Logo"
          />
          <StaticImage
            objectFit="contain"
            className="flex items-center w-full saturate-0 opacity-70"
            src="../images/fischer.png"
            alt="Rossignol Logo"
          />
          <StaticImage
            objectFit="contain"
            className="flex items-center w-full saturate-0 opacity-70"
            src="../images/dalbello.png"
            alt="Rossignol Logo"
          />
      </div>
    </div>
  );
};

export default Brands;
