import { StaticImage } from "gatsby-plugin-image";
import React, { useState, useLayoutEffect } from "react";
import { scrollToHash } from "../utils/scrollToHash";
import CloseIcon from "./CloseIcon";
import HamburgerIcon from "./HamburgerIcon";

const LandingMenu = () => {
  const [scrollPosition, setPosition] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.scrollY);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 py-2 z-50 w-screen h-auto flex transition-transform transform ease-in-out duration-500	 bg-white border-b border-gray-300 shadow-lg ${
        scrollPosition > 350 ? "translate-y-0" : "-translate-y-64"
      }`}
    >
      <div className="container mx-auto lg:flex">
        <div className="flex items-center px-4">
          <div className="flex items-center">
            <StaticImage
              src="../images/logo.png"
              alt="Görans Sportshop Logo"
              className="object-contain w-8 h-auto"
            />
            <h2 className="mt-1 ml-4 text-xl font-black tracking-wide text-gray-800 uppercase font-display">
              Görans Sportshop
            </h2>
          </div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="inline ml-auto lg:hidden"
          >
            {isOpen && <CloseIcon />}
            {!isOpen && <HamburgerIcon />}
          </button>
        </div>
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } ml-auto lg:flex font-medium font-display text-gray-800 mt-2 px-2`}
        >
          <a
            className="block p-2 rounded-lg hover:bg-gray-100"
            href="#hero"
            onClick={(e) => {
              setIsOpen(false);
              scrollToHash(e);
            }}
          >
            Hem
          </a>
          <a
            className="block p-2 rounded-lg hover:bg-gray-100"
            href="#about"
            onClick={(e) => {
              setIsOpen(false);
              scrollToHash(e);
            }}
          >
            Om oss
          </a>
          <a
            className="block p-2 rounded hover:bg-gray-100"
            href="#prices"
            onClick={(e) => {
              setIsOpen(false);
              scrollToHash(e);
            }}
          >
            Priser
          </a>
          <a
            className="block p-2 rounded hover:bg-gray-100"
            href="https://www.instagram.com/gorans_skidshop/"
            target="_blank"
            onClick={() => setIsOpen(false)}
          >
            Instagram
          </a>
          <a
            className="block p-2 rounded hover:bg-gray-100"
            href="#contact"
            onClick={(e) => {
              setIsOpen(false);
              scrollToHash(e);
            }}
          >
            Kontakt
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingMenu;
