import {
  faMountain,
  faStopwatch,
  faTools
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Feature from './Feature';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Features = () => {
  return (
    <div id="features" className="flex w-full h-auto bg-white">
      <div className="max-w-6xl px-8 mx-auto">
            <div className="w-full my-16 grid grid-cols-1 gap-12 md:grid-cols-3">
              <Feature icon={faMountain} title="Nära till backen" text="Perfekt beläget mitt på fjället nära och bra för alla oavsett om
                  man befinner sig på Hundfjället, Tandådalen, Högfjället eller
                  Lindvallen." />
              <Feature icon={faStopwatch} title="Snabb service" text="
                  Med vår långa erfarenhet och väl inarbetade rutiner ser vi till
                  att du slipper långa köer och väntetider" />
              <Feature icon={faTools} title="Fullutrustad Verkstad" text="Vår serviceverkstad är tillgänglig från morgon till kväll alla dagar för reparationer, Slipning, underhåll samt vallning."  />
              
          </div>
        </div>
    </div>
  );
};


export default Features;
