import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const About = () => {
  return (
    <div id="about" className="flex w-full h-auto">
      <div className="relative hidden w-1/2 h-auto lg:flex">
        <div className="grid w-full grid-cols-3 grid-rows-2 gap-1">
          <StaticImage
            alt="Skidåkare gul jacka"
            src="../images/left_image.png"
            className="object-contain object-center row-span-2"
          />
          <StaticImage
            alt="Skidåkare som hoppar med gul overall"
            src="../images/middle_top_image.png"
            className="object-contain object-center"
          />
          <StaticImage
            alt="Skidåkare gul overall"
            src="../images/right_image.png"
            className="object-contain object-center row-span-2"
          />
          <StaticImage
            alt="Skidåkare offpist"
            src="../images/middle_bottom_image.png"
            className="object-contain object-left"
          />
        </div>
        <svg
          className="absolute inset-y-0 right-0 z-0 hidden w-32 h-full ml-auto -mr-16 text-gray-800 transform rotate-180 fill-current lg:block"
          preserveAspectRatio="none"
          viewBox="0 0 100 100"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
      </div>
      <div className="relative z-10 flex flex-col w-full px-8 py-24 text-white bg-gray-800 lg:w-1/2 lg:flex">
        <div className="flex flex-col w-full xl:mx-auto xl:max-w-2xl">
          <div className="flex items-baseline justify-between w-full border-b-4 border-gray">
            <h2 className="inline-block pb-4 -mb-1 text-2xl font-bold uppercase border-b-4 border-red-800 font-display">
              Om oss
            </h2>
            <div className="text-white cursor-pointer hover:text-red-900">
              <a href="#contact" className="font-bold">
                Kontaktuppgifter
              </a>
              <FontAwesomeIcon className="ml-2" icon={faAngleDoubleRight} />
            </div>
          </div>
          <div className="flex-1 mt-8">
            <p>
              Vi är en väl inarbetad Sportshop sedan 30 år tillbaka 
              med uthyrning av längd och alpinutrustningar samt 
              försäljning av längd & tillbehör. 
              Nytt sedan förra säsongen är en slipmaskin samt det 
              mesta nytt i uthyrningen.
              <br />
              <br />
              Sedan 2021 drivs Görans skidshop av bröderna Ricky & Johnny (se
              bilder) då Göran gick i pension. -Vi försöker nu utveckla butiken
              vidare utan att tappa den genuina känslan av en trevlig shop som
              de var förr med bra utrustning och schyssta priser. <br />
              <br />
              Butiken ligger strategiskt på Sälenfjällens mitt{" "}
              <a href="#contact" className="font-bold underline">
                se karta
              </a>
              , 75m från sälenvägen och 50m från Högfjällshotellets
              välpreparerade längdspår.
            </p>
          </div>
          <div className="grid mt-8 overflow-hidden border-2 border-white bg-white lg:!hidden gap-1 grid-rows-2 grid-cols-3 w-full rounded-lg shadow-lg">
            <StaticImage
              alt="Skidåkare gul jacka"
              src="../images/left_image.png"
              className="object-contain object-center row-span-2"
            />
            <StaticImage
              alt="Skidåkare som hoppar med gul overall"
              src="../images/middle_top_image.png"
              className="object-contain object-top"
            />
            <StaticImage
              alt="Skidåkare gul overall"
              src="../images/right_image.png"
              className="object-contain object-center row-span-2"
            />
            <StaticImage
              alt="Skidåkare offpist"
              src="../images/middle_bottom_image.png"
              className="object-contain object-center"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
