import React from "react";

const PriceDaysHeader = ({ days}) => {
  return (
    <div className="flex items-center w-full px-4 py-2 bg-gray-100">    
      {days.map((day) => (
        <span key={day} className="flex-1 text-center">
          {day}
        </span>
      ))}
    </div>
  );
};

export default PriceDaysHeader;
