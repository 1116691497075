import React from "react";
import PriceDaysHeader from './PriceDaysHeader';


const PriceContentWrapper = ({ content, days }) => {
    return (
        <>
            { content.description && <p className="mt-8 text-xl font-medium">{content.description}</p> }
            <div className="mt-8 overflow-hidden bg-white border border-t border-gray-200 rounded-lg font-display">
                { content.isDays && <PriceDaysHeader days={days} /> }
                { content.entries && content.entries.map((entry, index) => (
                    <div key={index} className="flex flex-col w-full py-4 border-t border-gray-200">
                        <span className="pl-4 mb-2 font-semibold text-red-800">{entry.type}</span>
                        <div className="flex items-center w-full">
                            { entry.prices.map((price, index) => (
                                <span key={index} className="flex-1 font-semibold text-center">{price}</span>
                            )) }
                        </div>
                    </div>
                ))}
          </div>
        </>
    )
}

export default PriceContentWrapper;