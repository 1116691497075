import { faSnowflake } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import React, { Fragment } from "react";
import HamburgerIcon from "./HamburgerIcon";
import { Menu, Transition } from "@headlessui/react";
import { scrollToHash } from "../utils/scrollToHash";

const MobileHeroMenu = ({ weatherData }) => {
  const { weather, snow } = weatherData;
  return (
    <div className="flex items-center justify-between lg:hidden">
      <StaticImage
        src="../images/logo.png"
        className="flex w-8 h-auto"
        alt="Görans Logo"
      />
      <div className="text-sm">
        <span>
          {weather.temperature.toFixed()}&nbsp;&deg;C
          <i className={`wi wi-owm-${weather.icon} ml-2 text-red-800`}></i>
        </span>
        <span className="mx-2">/</span>
        <span>
          <FontAwesomeIcon className="mr-2 text-red-800" icon={faSnowflake} />
          {snow} cm
        </span>
      </div>
      <Menu as="div" className="relative">
        <div>
          <Menu.Button className="">
            <HamburgerIcon />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-50 flex flex-col w-56 mt-2 origin-top-right bg-white rounded-lg shadow-lg ring-1 ring-gray-200 ring-opacity-90 focus:outline-none">
            <Menu.Item
              as="a"
              href="#about"
              onClick={scrollToHash}
              className="w-full px-2 py-2 font-medium text-gray-800 font-display"
            >
              Om Oss
            </Menu.Item>
            <Menu.Item
              as="a"
              href="#about"
              onClick={scrollToHash}
              className="w-full px-2 py-2 font-medium text-gray-800 font-display"
            >
              Priser
            </Menu.Item>
            <Menu.Item
              as="a"
              href="https://www.instagram.com/gorans_skidshop/"
              target="_blank"
              className="w-full px-2 py-2 font-medium text-gray-800 font-display"
            >
              Instagram
            </Menu.Item>
            <Menu.Item
              as="a"
              href="#contact"
              onClick={scrollToHash}
              className="w-full px-2 py-2 font-medium text-gray-800 font-display"
            >
              Kontakt
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default MobileHeroMenu;
