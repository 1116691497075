import {
  faListOl,
  faSkiing,
  faSkiingNordic,
  faSnowboarding,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const PriceAreas = ({ prices, selected, setSelected }) => {
  const getIcon = (area) => {
    switch (area) {
      case "skiing":
        return faSkiing;
      case "snowboarding":
        return faSnowboarding;
      case "skiingnordic":
        return faSkiingNordic;
      case "multi":
        return faListOl;
      case "workshop":
        return faTools;
      default:
        break;
    }
  };

  return (
    <div className="grid gap-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
      {prices.map((item, index) => (
        <button
          type="button"
          key={index}
          onClick={() => setSelected(item)}
          className={`flex flex-col md:flex-row items-center justify-center font-display font-medium w-full px-6 py-4 border rounded-lg hover:bg-gray-800 hover:text-white curor-pointer border-gray-800 ${selected.area === item.area && 'bg-gray-800 text-white'}`}
        >
          <FontAwesomeIcon icon={getIcon(item.area)} className="mb-2 text-xl md:mb-0 md:mr-2" />
          <span>{item.title}</span>
        </button>
      ))}
    </div>
  );
};

export default PriceAreas;
