import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import HeroMenu from "./HeroMenu";
import MobileHeroMenu from "./MobileHeroMenu";
import HeroBookingCTA from "./HeroBookingCTA";
import axios from "axios";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../styles/weather-icons.min.css";

const Hero = () => {
  useEffect(() => {
    getWeather();
  }, []);

  const [weatherData, setWeatherData] = useState({
    weather: { icon: null, temperature: 0 },
    snow: 0,
  });

  const getWeather = async () => {
    const { data } = await axios.get(`${process.env.GATSBY_API_URL}/weather`);
    const { weather, snow } = data;
    setWeatherData({ weather, snow });
  };
  return (
    <div id="hero" className="flex justify-between w-full h-auto">
      <div className="w-full h-auto px-8 py-4 overflow-hidden bg-white lg:w-1/2">
        <HeroMenu weatherData={weatherData} />
        <MobileHeroMenu weatherData={weatherData} />
        <StaticImage
          src="../images/hero.jpg"
          className="object-contain object-center w-full h-56 mt-4 rounded-lg shadow-lg md:h-96 lg:!hidden"
          alt="Görans Cover image"
        />
        <div className="xl:mx-auto xl:max-w-2xl">
          <h1 className="mt-8 text-5xl font-black leading-none text-red-800 uppercase sm:text-6xl font-display md:text-7xl xl:text-7xl md:mt-12 xl:mt-24">
            Görans Sportshop
          </h1>
          <h2 className="mt-3 text-xl font-light leading-tight text-gray-600 sm:text-2xl font-body md:text-4xl lg:mt-6 xl:text-5xl">
            Skiduthyrningen på Sälenfjällens mitt, slipp långa köer och
            väntetider, hyr hos oss
          </h2>
          <HeroBookingCTA />
        </div>
      </div>
      <div className="relative hidden w-1/2 h-auto lg:flex">
        <StaticImage
          src="../images/hero.jpg"
          className="!absolute object-cover object-center w-full h-full"
          alt="Görans Cover image"
        />
        <svg
          className="absolute inset-y-0 z-0 hidden w-32 h-full -ml-16 text-white fill-current lg:block"
          preserveAspectRatio="none"
          viewBox="0 0 100 100"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
      </div>
    </div>
  );
};

export default Hero;
