export const scrollToHash = (e) => {
  e.preventDefault();

  console.log(e);
  const hash = e.target.hash;

  if (hash) {
    const el = document.querySelector(hash);

    el.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }
};
