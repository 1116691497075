import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faEnvelopeOpenText,
  faMapMarkerAlt,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import MapContainer from "./MapContainer";

const Contact = () => {
  return (
    <div
      id="contact"
      className="grid w-full h-auto grid-cols-1 grid-rows-2 lg:grid-rows-1 lg:grid-cols-2"
    >
      <div className="relative flex px-4 py-24 text-white bg-gray-800">
        <div className="flex flex-col w-full xl:mx-auto xl:max-w-2xl">
          <div className="container w-full mx-auto mb-10 border-b-4 border-white">
            <h2 className="inline-block pb-4 -mb-1 text-2xl font-bold uppercase border-b-4 border-red-800 font-display">
              Kontakt
            </h2>
          </div>
          <div className="flex flex-col justify-between flex-1 h-full">
            <a
              href="https://www.google.com/maps/place/H%C3%B6gfj%C3%A4llscenter+S%C3%A4len/@61.160487,13.1099751,17z/data=!4m8!1m2!2m1!1shogfjallscenter!3m4!1s0x46684c9e11d53bcf:0xd683da61fa701704!8m2!3d61.160289!4d13.109401"
              target="_blank"
              className="flex items-center hover:underline"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                className="text-red-800 fa-3x"
                icon={faMapMarkerAlt}
              />
              <div className="ml-8">
                <span className="block text-lg font-bold">Högfjällscenter</span>
                <span className="block">Linvägen 1 780 67 Sälen</span>
              </div>
            </a>
            <div className="flex mt-12">
              <FontAwesomeIcon
                className="text-red-800 fa-3x"
                icon={faPhoneVolume}
              />
              <div className="ml-8">
                <div className="block font-bold font-display">Under säsong</div>
                <a
                  href="tel: +46 280 218 10"
                  className="block text-lg font-bold hover:underline"
                >
                  +46 280 218 10
                </a>
                <div className="block mt-2 font-display">
                  Vid hög belastning
                </div>
                <a href="tel: +46 725 536373" className="block hover:underline">
                  +46 725 536373
                </a>
                <a href="tel: +46 760 289190" className="block hover:underline">
                  +46 760 289190
                </a>
              </div>
            </div>
            <a
              href="mailto:bokning@goranshc.se"
              className="flex items-center mt-12 hover:underline"
            >
              <FontAwesomeIcon
                className="text-red-800 fa-3x"
                icon={faEnvelopeOpenText}
              />
              <div className="ml-8">
                <span className="block text-lg font-bold">
                  bokning@goranshc.se
                </span>
              </div>
            </a>
            <div className="flex items-center mt-12">
              <FontAwesomeIcon className="text-red-800 fa-3x" icon={faClock} />
              <div className="ml-8">
                <span className="block text-lg font-bold">
                  Under säsong alla dagar 9 - 18
                </span>
                <span className="block">Säsong från DEC - APR</span>
              </div>
            </div>
          </div>
        </div>
        <svg
          className="z-10 absolute inset-y-0 right-0 hidden w-32 h-full -mr-16 text-gray-800 fill-current lg:!block"
          preserveAspectRatio="none"
          viewBox="0 0 100 100"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
      </div>
      <MapContainer />
    </div>
  );
};

export default Contact;
