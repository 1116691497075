import * as React from "react";
import About from "../components/About";
import Brands from "../components/Brands";
import Hero from "../components/Hero";
import Prices from "../components/Prices";
import "../styles/global.css";
import "@fontsource/poppins/900.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins";
import "@fontsource/lato";
import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato";
import BlogLanding from "../components/BlogLanding";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import LandingMenu from "../components/LandingMenu";
import Features from "../components/Features";
import "react-datepicker/dist/react-datepicker.css";
import SEO from "../components/SEO";

const IndexPage = () => {
  return (
    <div className="relative font-body">
      <LandingMenu />
      <Hero />
      <Features />
      <Brands />
      <About />
      <Prices />
      <Contact />
      <Footer />
    </div>
  );
};

export default IndexPage;

export const Head = () => (
  <>
    <SEO />
    <script
      id="cookieyes"
      type="text/javascript"
      src="https://cdn-cookieyes.com/client_data/5e899359a5b188574d72e6a5/script.js"
    ></script>
  </>
);
