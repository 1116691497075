import axios from "axios";
import React, { useEffect, useState } from "react";
import PriceContentTabs from "./PriceContentTabs";
import PriceDaysHeader from "./PriceDaysHeader";
import PriceAreas from "./PriceAreas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

const Prices = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [prices, setPrices] = useState([]);
  const [days, setDays] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);

  const handleAreaSelectionChange = (area) => {
    setSelectedArea(area);
    setSelectedContent(area.contents[0]);
  };

  const getPrices = async () => {
    const { data } = await axios.get(`${process.env.GATSBY_API_URL}/prices`);
    setPrices(data.prices);
    setDays(data.days);
    setSelectedArea(data.prices[0]);
    setSelectedContent(data.prices[0].contents[0]);
    setIsLoading(false);
  };

  useEffect(() => {
    getPrices();
  }, []);

  return (
    <div id="prices" className="w-full h-auto py-24 bg-gray-100">
      <div className="container px-4 mx-auto lg:px-8">
        <div className="flex items-baseline justify-between w-full border-b-4 border-gray">
          <h2 className="inline-block pb-4 -mb-1 text-2xl font-bold uppercase border-b-4 border-red-800 font-display">
            Priser
          </h2>
          <div className="text-red-800 cursor-pointer hover:underline">
            <a href="https://bokning.goranshc.se" target="_blank" className="font-bold">
              Boka
            </a>
            <FontAwesomeIcon className="ml-2" icon={faAngleDoubleRight} />
          </div>
        </div>
        <div className="flex flex-col mt-16 overflow-hidden">
          {isLoading && (
            <div className="flex flex-col items-center justify-center w-full h-96">
              <svg
                className="w-12 h-12 mr-3 -ml-1 text-red-800 animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <h3 className="mt-3 text-xl text-red-800 font-display">
                Laddar priser...
              </h3>
            </div>
          )}
          {!isLoading && (
              <div className="flex flex-col">
              <PriceAreas
                prices={prices}
                selected={selectedArea}
                setSelected={handleAreaSelectionChange}
              />
              <PriceContentTabs days={days} contents={selectedArea.contents} />
              </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Prices;
