import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Feature = ({ icon, title, text }) => {
  return (<div className="p-8 mt-8 text-center text-white bg-gray-800 rounded-lg shadow-lg">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex items-center justify-center w-16 h-16 mb-4 text-gray-800 bg-red-800 rounded-full">
                    <FontAwesomeIcon icon={icon} className="text-3xl text-white" />
                  </div>
                  <span className="text-xl font-medium font-display">
                    {title}
                  </span>
                </div>
                <p className="mt-2">
                  {text} 
                </p>
              </div>
);
};

export default Feature;
