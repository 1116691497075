import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import { format } from "date-fns";

registerLocale("sv", sv);

const HeroBookingCTA = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const goToBooking = () => {
    let url = process.env.GATSBY_BOOKING_URL;

    if (startDate) url += `?startDate=${getDateString(startDate)}`;

    if (endDate && !startDate) url += `?endDate=${getDateString(endDate)}`;

    if (endDate && startDate) url += `&endDate=${getDateString(endDate)}`;

    window.open(url, "_blank").focus();
  };

  const getDateString = (date) => {
    return format(date, "yyyy-MM-dd", { locale: sv });
  };

  return (
    <div className="relative z-10 flex flex-col w-full h-auto mt-6 text-sm font-medium font-display md:my-12 md:text-lg xl:text-xl">
      <p className="ml-4">
        Boka i tid och få upp till
        <span className="text-red-800">&nbsp;20%&nbsp;</span>
        rabatt
      </p>

      <div className="flex items-center mt-1 overflow-hidden bg-white border border-gray-200 rounded-lg shadow-lg cta-container">
        <DatePicker
          className="flex-1 w-full py-4 border-r border-gray-200 cursor-pointer hover:bg-gray-50"
          selected={startDate}
          locale={sv}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          withPortal
          endDate={endDate}
          customInput={
            <button className="flex-1 py-4 border-r border-gray-200 cursor-pointer hover:bg-gray-50">
              <div className="flex items-center justify-center w-full">
                <FontAwesomeIcon icon={faCalendarAlt} />
                {!startDate && <span className="ml-2">Hämtas</span>}
                {startDate && (
                  <span className="ml-2">{getDateString(startDate)}</span>
                )}
              </div>
            </button>
          }
        />
        <DatePicker
          selected={endDate}
          locale={sv}
          className="flex-1 w-full py-4 border-r border-gray-200 cursor-pointer hover:bg-gray-50"
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          withPortal
          minDate={startDate}
          customInput={
            <button className="flex-1 py-4 cursor-pointer hover:bg-gray-50">
              <div className="flex items-center justify-center w-full">
                <FontAwesomeIcon icon={faCalendarAlt} />
                {!endDate && <span className="ml-2">Återlämnas</span>}
                {endDate && (
                  <span className="ml-2">{getDateString(endDate)}</span>
                )}
              </div>
            </button>
          }
        />
        <button
          type="button"
          onClick={goToBooking}
          className="h-full px-4 py-4 font-medium text-white bg-red-800 hover:bg-red-900 font-display"
        >
          Boka!
        </button>
      </div>
    </div>
  );
};

export default HeroBookingCTA;
