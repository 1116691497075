import React, { useEffect, useState } from "react";
import mapStyles from "../utils/mapStyles";
const MapContainer = () => {
  const [isScriptLoading, setIsScriptLoading] = useState(true);
  useEffect(() => {
    const script = document.getElementById("google-maps-script");
    if (script) {
      initMap();
    } else {
      const newScript = document.createElement("script");
      newScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDs_vR7cyqlrDhIlSZbq9QDIY9Ti6kIMLA`;
      newScript.id = "google-maps-script";
      newScript.addEventListener("load", () => {
        initMap();
      });

      document.body.append(newScript);
    }
  }, []);

  const initMap = () => {
    const center = {
      lat: 61.16032,
      lng: 13.109224,
    };

    const map = new window.google.maps.Map(document.getElementById("map"), {
      center,
      zoom: 15,
      styles: mapStyles,
      disableDefaultUI: true,
    });

    const marker = new window.google.maps.Marker({
      position: center,
      map: map,
    });

    const contentString = `
      <a
        target="_blank"
        href="https://www.google.com/maps/place/H%C3%B6gfj%C3%A4llscenter+S%C3%A4len/@61.160487,13.1099751,17z/data=!4m8!1m2!2m1!1shogfjallscenter!3m4!1s0x46684c9e11d53bcf:0xd683da61fa701704!8m2!3d61.160289!4d13.109401"
        class="flex items-center"
        rel="noreferrer"
      >
        <span class="text-lg text-red-800 cursor-pointer">
          Görans sportshop
        </span>
      </a>
    `;

    const infowindow = new window.google.maps.InfoWindow({
      content: contentString,
    });

    window.google.maps.event.addListener(marker, "click", function () {
      infowindow.open(map, marker);
    });

    infowindow.open(map, marker);

    setIsScriptLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-gray-100">
      {isScriptLoading && (
        <>
          <svg
            className="w-12 h-12 mr-3 -ml-1 text-red-800 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <h3 className="mt-3 text-xl text-red-800 font-display">
            Laddar karta...
          </h3>
        </>
      )}
      <div
        id="map"
        className={`hidden w-full h-full ${!isScriptLoading && "!block"}`}
      ></div>
    </div>
  );
};

export default MapContainer;
